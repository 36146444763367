import React from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import "font-awesome/css/font-awesome.min.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`Chris`, `Hiester`, `Web`, `Developer`]} />
    <Helmet>
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.7.2/css/all.css"
        integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr"
        crossorigin="anonymous"
      />
      <meta
        name="google-site-verification"
        content="IOUBzFekeCOVOXLVxTlAmF8DHV_rbC8zd_7msryls44"
      />
      <meta
        name="google-site-verification"
        content="T76X1DTU1Zn7a9myGKjIr_ysf-8_nO_eL6GTkWYiPuo"
      />
    </Helmet>
    <article>
      <Card className="Card">
        <CardContent>
          <div className="media">
            <div className="img">
              <Image />
            </div>
            <h1>Chris Hiester</h1>
            <div className="content">
              <p>Experienced Web Developer</p>

              <div className="footer">
                <address>
                  <ul>
                    <li>
                      <i className="fas fa-suitcase" />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://portfolio.hiester.com"
                      >
                        Portfolio
                      </a>
                    </li>
                    <li>
                      <i className="fab fa-linkedin" />
                      <a
                        target="_blank"
                        title="LinkedIn"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/in/hiester"
                      >
                        LinkedIn
                      </a>
                    </li>
                  </ul>
                </address>{" "}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </article>
  </Layout>
)

export default IndexPage
